<template>
    <div class="search-form-filters">

        <b-button v-b-modal.modal-filters variant="light" class="btn-with-icon btn-block desktop-only">
            <div class="btn-with-icon-inner">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1789 6.15323H11.0177C10.8988 5.72913 10.6453 5.3552 10.2952 5.08784C9.94522 4.82048 9.51769 4.67419 9.07724 4.67102C8.63633 4.67918 8.20939 4.82711 7.85794 5.09348C7.5065 5.35986 7.24876 5.73094 7.12175 6.15323H1.45382C1.30392 6.15323 1.16022 6.21278 1.05422 6.31877C0.948226 6.42477 0.888672 6.56853 0.888672 6.71844C0.888672 6.86834 0.948226 7.0121 1.05422 7.1181C1.16022 7.2241 1.30392 7.28364 1.45382 7.28364H7.12175C7.23785 7.70924 7.49069 8.08487 7.84128 8.35264C8.19187 8.62041 8.6207 8.76546 9.06186 8.76546C9.50301 8.76546 9.93202 8.62041 10.2826 8.35264C10.6332 8.08487 10.8859 7.70924 11.002 7.28364H12.1632C12.2375 7.28384 12.3111 7.26935 12.3798 7.24099C12.4486 7.21264 12.511 7.17099 12.5635 7.11843C12.6161 7.06587 12.6577 7.00345 12.686 6.93474C12.7144 6.86603 12.7289 6.79239 12.7287 6.71806C12.7319 6.64431 12.72 6.57069 12.6937 6.50171C12.6674 6.43274 12.6273 6.36987 12.5758 6.31698C12.5243 6.26408 12.4625 6.22227 12.3943 6.19411C12.3261 6.16595 12.2528 6.15204 12.1789 6.15323ZM9.07724 7.63469C8.83431 7.6341 8.60146 7.53734 8.42968 7.36557C8.25791 7.1938 8.16126 6.96098 8.16066 6.71806C8.16066 6.47495 8.25723 6.24181 8.42913 6.06991C8.60104 5.89801 8.83413 5.80143 9.07724 5.80143C9.32034 5.80143 9.55344 5.89801 9.72534 6.06991C9.89724 6.24181 9.99381 6.47495 9.99381 6.71806C9.99322 6.96098 9.89657 7.1938 9.72479 7.36557C9.55302 7.53734 9.32016 7.6341 9.07724 7.63469Z" fill="#333333"/>
                <path d="M1.45355 2.80705H2.66058C2.77667 3.23266 3.02951 3.60827 3.3801 3.87604C3.73069 4.14381 4.15953 4.28887 4.60068 4.28887C5.04183 4.28887 5.47084 4.14381 5.82143 3.87604C6.17203 3.60827 6.42468 3.23266 6.54078 2.80705H12.1787C12.2529 2.80725 12.3265 2.79277 12.3951 2.76445C12.4638 2.73614 12.526 2.69454 12.5785 2.64205C12.6309 2.58956 12.6727 2.52722 12.701 2.4586C12.7293 2.38998 12.7437 2.31645 12.7435 2.24222C12.745 2.16759 12.7316 2.09341 12.7037 2.02415C12.6759 1.95489 12.6343 1.89197 12.5816 1.83916C12.5288 1.78634 12.4658 1.74472 12.3966 1.71679C12.3274 1.68886 12.2533 1.67521 12.1787 1.67664H6.54078C6.42468 1.25104 6.17203 0.875417 5.82143 0.60765C5.47084 0.339882 5.04183 0.194824 4.60068 0.194824C4.15953 0.194824 3.73069 0.339882 3.3801 0.60765C3.02951 0.875417 2.77667 1.25104 2.66058 1.67664H1.45355C1.37929 1.67654 1.3059 1.6911 1.23727 1.7195C1.16865 1.7479 1.10625 1.78957 1.05378 1.84212C1.0013 1.89467 0.959749 1.95706 0.931443 2.02572C0.903137 2.09438 0.888576 2.16795 0.888773 2.24222C0.887347 2.31678 0.900999 2.39087 0.928879 2.46004C0.95676 2.52921 0.998292 2.59205 1.05103 2.64479C1.10376 2.69752 1.16664 2.73908 1.23581 2.76696C1.30498 2.79484 1.37899 2.80848 1.45355 2.80705ZM4.60416 1.32558C4.84708 1.32618 5.07975 1.42295 5.25153 1.59472C5.4233 1.76649 5.52014 1.99929 5.52073 2.24222C5.52297 2.36321 5.50081 2.48341 5.45554 2.59563C5.41026 2.70785 5.34277 2.80979 5.25721 2.89536C5.17164 2.98093 5.06964 3.04836 4.95742 3.09363C4.84519 3.13891 4.72515 3.16109 4.60416 3.15885C4.36123 3.15826 4.12837 3.06149 3.9566 2.88972C3.78483 2.71794 3.68799 2.48514 3.6874 2.24222C3.69326 2.00147 3.79136 1.77216 3.96136 1.6016C4.13137 1.43104 4.36032 1.33224 4.60104 1.32558H4.60416Z" fill="#333333"/>
                <path d="M12.1787 10.6292H6.54078C6.42468 10.2036 6.17203 9.82793 5.82143 9.56016C5.47084 9.2924 5.04183 9.14734 4.60068 9.14734C4.15953 9.14734 3.73069 9.2924 3.3801 9.56016C3.02951 9.82793 2.77667 10.2036 2.66058 10.6292H1.45355C1.37929 10.6291 1.3059 10.6436 1.23727 10.672C1.16865 10.7004 1.10625 10.7421 1.05378 10.7946C1.0013 10.8472 0.959749 10.9096 0.931443 10.9782C0.903137 11.0469 0.888576 11.1205 0.888773 11.1947C0.887347 11.2693 0.900999 11.3434 0.928879 11.4126C0.95676 11.4817 0.998292 11.5446 1.05103 11.5973C1.10376 11.6501 1.16664 11.6916 1.23581 11.7195C1.30498 11.7474 1.37899 11.761 1.45355 11.7596H2.66058C2.77667 12.1852 3.02951 12.5608 3.3801 12.8286C3.73069 13.0963 4.15953 13.2414 4.60068 13.2414C5.04183 13.2414 5.47084 13.0963 5.82143 12.8286C6.17203 12.5608 6.42468 12.1852 6.54078 11.7596H12.1787C12.2529 11.7598 12.3265 11.7453 12.3951 11.717C12.4638 11.6887 12.526 11.6471 12.5785 11.5946C12.6309 11.5421 12.6727 11.4797 12.701 11.4111C12.7293 11.3425 12.7437 11.269 12.7435 11.1947C12.745 11.1201 12.7316 11.0459 12.7037 10.9767C12.6759 10.9074 12.6343 10.8445 12.5816 10.7917C12.5288 10.7389 12.4658 10.6972 12.3966 10.6693C12.3274 10.6414 12.2533 10.6277 12.1787 10.6292ZM4.60251 12.1114C4.35964 12.1106 4.12705 12.0137 3.95532 11.842C3.78359 11.6703 3.68672 11.4376 3.68593 11.1947C3.68593 10.9516 3.7825 10.7185 3.9544 10.5466C4.12631 10.3747 4.3594 10.2781 4.60251 10.2781C4.84561 10.2781 5.07889 10.3747 5.2508 10.5466C5.4227 10.7185 5.51927 10.9516 5.51927 11.1947C5.51847 11.4379 5.42138 11.6708 5.24933 11.8426C5.07728 12.0143 4.84417 12.111 4.60104 12.1114H4.60251Z" fill="#333333"/>
                </svg>
                <span>Filtri</span>
                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2635 1.21558L8.83 8.64915L1.39648 1.21558" stroke="#333333" stroke-width="2.25033" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </b-button>

        <b-modal id="modal-filters" size="xl" class="desktop-only">
            <b-form-group>
                <b-row>
                    <b-col 
                        v-for="amenity in amenities"
                        v-bind:key="amenity.label"
                        cols="12"
                        lg="3"
                    >
                        <b-form-group>
                            <strong>{{amenity.label}}</strong>
                            <b-form-checkbox-group :id="'amenities-'+amenity.label" v-model="selectedAmenities" :name="amenity.label" stacked>
                                <b-form-checkbox 
                                    v-for="f in amenity.filters" 
                                    v-bind:key="f.label" 
                                    :value="f.value"
                                >
                                    {{f.text}}
                                </b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        lg="3"
                    >
                        <b-form-checkbox-group id="categories" v-model="selectedCategories" name="categories" stacked>
                            <b-form-checkbox 
                                v-for="(category,index) in categories" 
                                v-bind:key="index" 
                                :value="index"
                            >
                                {{category}}
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-col>
                </b-row>
            </b-form-group>
                <template v-slot:modal-footer>
                    <b-row>
                        <b-col cols="12" @click="filterChange();$bvModal.hide('modal-filters')	">
                            <b-button variant="secondary">APPLICA FILTRI</b-button>
                        </b-col>
                    </b-row>
                </template>

        </b-modal>

        <b-form-group class="filters mobile-only">
            <b-row>
                <b-col 
                    v-for="amenity in amenities"
                    v-bind:key="amenity.label"
                    cols="12"
                    lg="3"
                >
                    <b-form-group v-if="amenity.label !== 'Refundable'">
                        <strong v-if="amenity.label == 'Property'">Caratteristiche struttura</strong>
                        <strong v-else-if="amenity.label == 'Rate'">Servizi inclusi</strong>
                        <strong v-else>{{amenity.label}}</strong>
                        <b-form-checkbox-group :id="'amenities-'+amenity.label" v-model="selectedAmenities" :name="amenity.label" stacked>
                            <b-form-checkbox 
                                v-for="f in amenity.filters" 
                                v-bind:key="f.label" 
                                :value="f.value"
                            >
                                {{f.text}}
                            </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    lg="3"
                >
                    <strong>Tipologia struttura</strong>
                    <b-form-checkbox-group id="categories" v-model="selectedCategories" name="categories" stacked>
                        <b-form-checkbox 
                            v-for="(category,index) in categories" 
                            v-bind:key="index" 
                            :value="category.value"
                            @change="categoryChanged()"
                        >
                            {{category.text}}
                        </b-form-checkbox>
                    </b-form-checkbox-group>
                </b-col>
                <b-col cols="12" class="apply-filters-btn" @click="filterChange()">
                    <b-button>APPLICA FILTRI</b-button>
                </b-col>
            </b-row>
        </b-form-group>
        <!--
            <template v-slot:modal-footer>
                <b-row>
                    <b-col cols="12" @click="filterChange()">
                        <b-button variant="secondary">APPLICA FILTRI</b-button>
                    </b-col>
                </b-row>
            </template>
        -->

        
    </div>
</template>

<script>
    import Vue from 'vue'
    export default {
        props:{
            resultsFiltered: Array,
        },
        computed: {
            selectedAmenities: {
                get() {
                    return this.$store.state.amenities
                },
                set(value) {
                    this.mixinSendMutation('setAmenities',value)
                }
            },
            selectedCategories: {
                get() {
                    return this.$store.state.categories
                },
                set(value) {
                    this.mixinSendMutation('setCategories',value)
                }
            }
        },
        data(){
            return {
                config: Vue.prototype.$config,
                amenities: {},
                categories: []
            }
        },
        mounted()
        {
            let amenities = {
                property:{
                    label: "Property", filters: []
                },
                rate:{
                    label: "Rate", filters: []
                },
                refundable:{
                    label: "Refundable", filters: []
                }
            }
            for (let key in this.config.amenities) {
                let level = this.config.amenities[key].level
                amenities[level].filters.push({
                    text: this.config.amenities[key].label,
                    value: key
                })
            }
            this.amenities = amenities
            this.salabamCategories = this.config.salabamCategories




            let categories = []

            for (let key in this.config.salabamCategories) {
                let text = this.config.salabamCategories[key],
                    value = key
                    /*,
                    number = this.resultsFiltered.filter(function (el) {
                        return el.salabamCategory == key
                    }).length*/
                
                //if(number !== 0){
                    categories.push({
                        text: text,
                        value: value,
                        //number: number
                    })
                //}
            }

            /*
            categories.sort(function(a, b) {
                return b.number - a.number;
            })
            */

            this.categories = categories
        },    
        methods: {
            filterChange()
            {
                this.$emit('filter-changed',{
                    selectedAmenities: this.selectedAmenities,
                    selectedCategories: this.selectedCategories
                })
            },
            categoryChanged()
            {
                this.$emit('category-changed')
            }
        }
    }
</script>